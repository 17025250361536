import React, { useEffect } from "react";
import { Image, Container, Navbar, Dropdown, Button } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
// import Moon from "../../assets/images/moon-vector.png";
import Bell from "../../assets/images/bell-vector.png";
import User from "../../assets/images/user-img.png";
import Arrow from "../../assets/images/dropdown-vector.png"
import Settings from "../../assets/images/settings-vector.png";
import Logout from "../../assets/images/logout-vector.png";
import { getAllDropdownProjectsAsync } from "slices/project/projectSlice";
import { logoutAsync } from "../../slices/auth/authSlice";
import { setProjectId } from "slices/project/projectSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";

function NavBar() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector((state: RootState) => {
        const firstName = state.auth.user?.first_name || "";
        const lastName = state.auth.user?.last_name || "";
        return `${firstName} ${lastName}`.trim();
    });
    const userData = useAppSelector((state: any) => state.user?.userData?.user);
    const isAdmin = userData?.system_role === 'admin';
    const allProjects = useAppSelector((state) => state.project.projects?.data);
    const assignedProjects = userData?.projects || [];
    const projects = isAdmin ? allProjects : assignedProjects;
    const currentProjectId = useAppSelector(
        (state: RootState) => state.project.projectId
    );
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );

    useEffect(() => {
        if (companyId) {
            dispatch(getAllDropdownProjectsAsync({ companyId }));
        }
    }, [companyId, dispatch]);



    const handleProjectClick = (projectId: string) => {
        localStorage.removeItem("projectId");
        dispatch(setProjectId(projectId));
        localStorage.setItem("projectId", projectId);
        navigate(`/dashboard/${projectId}`);
    };

    console.log("Current Project ID:", currentProjectId);
    console.log("Projects:", assignedProjects);

    const handleViewAllProjects = () => {
        navigate("/projects");
    };
    const handleLogout = () => {
        dispatch(logoutAsync()).then(() => {
            navigate("/sign-in");
            localStorage.clear();
            toast.success("Logged out successfully");
        });
    };
    return (
        <Navbar className="bg-color-9 fixed-top">
            <Container >
                <Navbar.Brand>
                    <Image src={Logo} width={90} height={30} />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        <div className="d-flex align-items-center">
                            <Dropdown>
                                <Dropdown.Toggle
                                    className="dropdown-toggle"
                                    variant="transparent"
                                    style={{
                                        background: "none",
                                        border: "none",
                                        boxShadow: "none",
                                    }}
                                >                                    <span>Projects <Image src={Arrow} /></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {projects.length > 0 ? (
                                        projects?.map((project: any) => (
                                            <Dropdown.Item
                                                key={project.id}
                                                onClick={() => handleProjectClick(project.id)}
                                            >                                                {project.project_name}
                                            </Dropdown.Item>
                                        ))
                                    ) : (
                                        <Dropdown.Item>No projects available</Dropdown.Item>
                                    )}
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={handleViewAllProjects}
                                    >
                                        View All Projects
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <Image src={Moon} className="mx-2" /> */}
                            <Image src={Bell} className="mx-2" />
                            <Image src={User} className="mx-2" />
                            <Dropdown>
                                <Dropdown.Toggle
                                    className="dropdown-toggle"
                                    variant="transparent"
                                    style={{
                                        background: "none",
                                        border: "none",
                                        boxShadow: "none",
                                    }}
                                >
                                    <span className="mb-0 mx-1">{user} <Image src={Arrow} /></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        {isAdmin && (
                                            <Link to="/settings-profile" className="link-styles px-2">
                                                <Image src={Settings} />
                                                <span className="mx-2">Settings</span>
                                            </Link>

                                        )}                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Button className="logout-btn border-0" onClick={handleLogout}>
                                            <Image src={Logout} />
                                            <span className="mx-2">Logout</span>
                                        </Button>                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar