import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";

export interface TaskData {
	projectId: string | undefined | null;
	company_id: string | null;
	task_name: string;
	assigned_to: number;
	start_date: string;
	end_date: string;
	task_status: string;
	task_color: string;
}

export interface CreateTaskResponse {
	data: TaskData;
	message: string;
	error: boolean;
}

export interface Task extends TaskData {
	id: string;
}

export interface GetAllTasksResponse {
	data: Task[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchTasksResponse {
	data: Task[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface UpdateTaskResponse {
	data: Task[];
	message: string;
	error: boolean;
}

export interface GetATaskResponse {
	data: TaskData;
	message: string;
	error: boolean;
}

export interface DeleteTaskResponse {
	message: string;
	error: boolean;
}
export interface IssuesData {
	projectId: string | undefined | null;
	company_id: string | null;
	issue_name: string;
	associated_task: number;
	assigned_to: number;
	priority: string;
	status: string;
	image: string;
}

export interface CreateIssueResponse {
	data: IssuesData;
	message: string;
	error: boolean;
}

export interface Issue extends IssuesData {
	id: string;
	image_url: string;
}

export interface GetAllIssuesResponse {
	data: Issue[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchIssuesResponse {
	data: Issue[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface UpdateIssueResponse {
	data: Issue[];
	message: string;
	error: boolean;
}

export interface GetAnIssueResponse {
	data: IssuesData;
	message: string;
	error: boolean;
}

export interface DeleteIssueResponse {
	message: string;
	error: boolean;
}

export const createTask = async (
	data: TaskData,
): Promise<CreateTaskResponse> => {
	try {
		const response = await axiosInstance.post(
			`/projects/${data.projectId}/tasks/create`,
			data,
		);
		toast.success("Task created successfully.");
		return response.data;
	} catch (error: any) {
		toast.error(`${error.response.data.message}`);
		return error.response.data;
	}
};

export const updateTask = async (data: Task): Promise<UpdateTaskResponse> => {
	try {
		const response = await axiosInstance.put(
			`/projects/${data.projectId}/tasks/update/${data.id}`,
			data,
		);
		toast.success("Task updated successfully.");
		return response.data;
	} catch (error: any) {
		toast.error(`${error.response.data.message}`);
		return error.response.data;
	}
};

export const getAllTasks = async (
	company_id: string,
	projectId: string,
	page: number,
): Promise<GetAllTasksResponse> => {
	
	try {
		const response = await axiosInstance.get(
			`/projects/${projectId}/tasks?company_id=${company_id}&page=${page}`,
		);
		
		
		return response.data;
	} catch (error: any) {
		
		toast.error(`${error.response.data.message}`);
		return error.response.data;
	}
};
export const searchTasks = async (
	company_id: string,
	projectId: string,
	searchTerm: string,
	page: number,
): Promise<SearchTasksResponse> => {
	toast.loading("Searching tasks");
	try {
		const response = await axiosInstance.get(
			`/projects/${projectId}/tasks/search/${searchTerm}?company_id=${company_id}&page=${page}`,
		);
		toast.dismiss();
		toast.success("Search successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(`${error.response.data.message}`);
		return error.response.data;
	}
};

export const getAllDropdownTasks = async (
	company_id: string,
	projectId: string,
): Promise<GetAllTasksResponse> => {
	try {
		const response = await axiosInstance.get(
			`/projects/${projectId}/tasks?company_id=${company_id}&response_type=dropdown`,
		);
		return response.data;
	} catch (error: any) {
		return error.response.data;
	}
};

export const getATask = async (
	id: string,
	company_id: string,
	projectId: string,
): Promise<GetATaskResponse> => {
	try {
		const response = await axiosInstance.get(
			`/projects/${projectId}/tasks/${id}?company_id=${company_id}`,
		);
		return response.data;
	} catch (error: any) {
		toast.error(`${error.response.data.message}`);
		return error.response.data;
	}
};

export const deleteTask = async (
	id: string,
	projectId: string,
	company_id: string,
): Promise<DeleteTaskResponse> => {
	toast.loading("Deleting task");
	try {
		const response = await axiosInstance.delete(
			`/projects/${projectId}/tasks/delete/${id}?company_id=${company_id}`,
		);
		toast.dismiss();
		toast.info("Task deleted successfully!");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(`${error.response.data.message}`);
		return error.message;
	}
};

export const createIssue = async (
	data: IssuesData,
): Promise<CreateIssueResponse> => {
	try {
		const response = await axiosInstance.post(
			`/projects/${data.projectId}/issues/create`,
			data,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			},
		);
		toast.success("Issue created successfully");
		return response.data;
	} catch (error: any) {
		toast.error(`${error.response.data.message}`);
		return error.response.data;
	}
};

export const updateIssue = async (
	data: Issue,
): Promise<UpdateIssueResponse> => {
	try {
		const response = await axiosInstance.put(
			`/projects/${data.projectId}/issues/update/${data.id}`,
			data,
		);
		toast.success("Issue updated successfully.");
		return response.data;
	} catch (error: any) {
		toast.error(`${error.response.data.message}`);
		return error.response.data;
	}
};

export const getAllIssues = async (
	company_id: string,
	projectId: string,
	page: number,
): Promise<GetAllIssuesResponse> => {
	
	try {
		const response = await axiosInstance.get(
			`/projects/${projectId}/issues?company_id=${company_id}&page=${page}`,
		);
		
		return response.data;
	} catch (error: any) {
		
		return error.response.data;
	}
};
export const searchIssues = async (
	company_id: string,
	projectId: string,
	searchTerm: string,
	page: number,
): Promise<SearchIssuesResponse> => {
	toast.loading("Searching issues");
	try {
		const response = await axiosInstance.get(
			`/projects/${projectId}/issues/search/${searchTerm}?company_id=${company_id}&page=${page}`,
		);
		toast.dismiss();
		toast.success("Search successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(`${error.response.data.message}`);
		return error.response.data;
	}
};

export const getAnIssue = async (
	id: string,
	company_id: string,
	projectId: string,
): Promise<GetAnIssueResponse> => {
	try {
		const response = await axiosInstance.get(
			`/projects/${projectId}/issues/${id}?company_id=${company_id}`,
		);
		return response.data;
	} catch (error: any) {
		toast.error(`${error.response.data.message}`);
		return error.response.data;
	}
};

export const deleteIssue = async (
	id: string,
	projectId: string,
	company_id: string,
): Promise<DeleteIssueResponse> => {
	try {
		const response = await axiosInstance.delete(
			`/projects/${projectId}/issues/delete/${id}?company_id=${company_id}`,
		);
		toast.info("Issue deleted successfully");
		return response.data;
	} catch (error: any) {
		toast.error(`${error.response.data.message}`);
		return error.message;
	}
};
