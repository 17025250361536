import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
	Inventory,
	ConfirmAnInventoryData,
	StoreRequestData,
	GetAllInventoryResponse,
	GetConfirmedInventoryResponse,
	GetAnInventoryResponse,
	ConfirmAnInventoryResponse,
	CreateStoreRequestResponse,
	GetAllStoreRequestsResponse,
	GetAStoreRequestResponse,
	GetLowStockResponse,
	StoreRequests,
	ReturnUsageData,
	Usage,
	ReturnUsageResponse,
	GetAllUsagesResponse,
	GetAUsageResponse,
	returnUsage,
	searchInventory,
	searchConfirmedInventory,
	searchStoreRequests,
	searchUsages,
} from "../../services/inventory/inventoryService";
import {
	getAllInventory,
	getConfirmedInventory,
	getAnInventory,
	confirmAnInventory,
	createStoreRequest,
	getAllStoreRequests,
	getAStoreRequest,
	getAllUsages,
	getAUsage,
	getLowStock,
} from "../../services/inventory/inventoryService";

export interface InventoryState {
	confirmInventoryResponse: ConfirmAnInventoryResponse | null;
	getAllInventoryResponse: GetAllInventoryResponse | null;
	getConfirmedInventoryResponse: GetConfirmedInventoryResponse | null;
	getAnInventoryResponse: GetAnInventoryResponse | null;
	createStoreRequestResponse: CreateStoreRequestResponse | null;
	getAllStoreRequests: GetAllStoreRequestsResponse | null;
	getAStoreRequest: GetAStoreRequestResponse | null;
	returnUsageResponse: ReturnUsageResponse | null;
	getAllUsagesResponse: GetAllUsagesResponse | null;
	getAUsageResponse: GetAUsageResponse | null;
	getLowStockResponse: GetLowStockResponse | null;
	inventory?: GetAllInventoryResponse | null;
	confirmedInventory?: GetConfirmedInventoryResponse | null;
	inventoryItem: Inventory | null;
	storeRequests: GetAllStoreRequestsResponse | null;
	storeRequest?: StoreRequests | null;
	usages: GetAllUsagesResponse | null;
	usage: Usage | null;
	inventoryItemId?: string | null;
	storeRequestId?: string | null;
	usageItemId?: string | null;
	error?: string | null;
	meta?: any;
}

const initialState: InventoryState = {
	confirmInventoryResponse: null,
	getAllInventoryResponse: null,
	getConfirmedInventoryResponse: null,
	getAnInventoryResponse: null,
	createStoreRequestResponse: null,
	getAllStoreRequests: null,
	getAStoreRequest: null,
	returnUsageResponse: null,
	getAllUsagesResponse: null,
	getLowStockResponse: null,
	getAUsageResponse: null,
	inventory: null,
	confirmedInventory: null,
	inventoryItem: null,
	storeRequests: null,
	storeRequest: null,
	usages: null,
	usage: null,
	inventoryItemId: null,
	storeRequestId: null,
	usageItemId: null,
	error: null,
	meta: null,
};

export const confirmAnInventoryAsync = createAsyncThunk(
	"inventory/confirmInventory",
	async (data: ConfirmAnInventoryData, { dispatch, rejectWithValue }) => {
		try {
			const response = await confirmAnInventory(data);
			return response;
		} catch (err) {
			return rejectWithValue("Failed to confirm inventory.");
		}
	},
);
export const getAllInventoryAsync = createAsyncThunk(
	"inventory/getAllInventory",
	async ({
		company_id,
		projectId,
		page,
	}: {
		company_id: string;
		projectId: string;
		page: number;
	}) => {
		const response = await getAllInventory(company_id, projectId, page);
		return response;
	},
);
export const searchInventoryAsync = createAsyncThunk(
	"inventory/searchInventory",
	async (
		{
			company_id,
			projectId,
			searchTerm,
			page,
		}: {
			company_id: string | null;
			projectId: string | undefined;
			searchTerm: string | null;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchInventory(
				company_id,
				projectId,
				searchTerm,
				page,
			);
			return response;
		} catch (err) {
			return rejectWithValue("Search inventory failed.");
		}
	},
);
export const getAnInventoryItemAsync = createAsyncThunk(
	"inventory/getAnInventoryItem",
	async ({
		company_id,
		projectId,
		id,
	}: {
		company_id: string;
		projectId: string;
		id: string | null;
	}) => {
		const response = await getAnInventory(company_id, projectId, id);
		return response;
	},
);
export const getConfirmedInventoryAsync = createAsyncThunk(
	"inventory/getConfirmedInventory",
	async ({
		company_id,
		projectId,
		page,
	}: {
		company_id: string;
		projectId: string;
		page: number;
	}) => {
		const response = await getConfirmedInventory(company_id, projectId, page);
		return response;
	},
);
export const searchConfirmedInventoryAsync = createAsyncThunk(
	"inventory/searchConfirmedInventory",
	async (
		{
			company_id,
			projectId,
			searchTerm,
			page,
		}: {
			company_id: string | null;
			projectId: string | undefined;
			searchTerm: string | null;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchConfirmedInventory(
				company_id,
				projectId,
				searchTerm,
				page,
			);
			return response;
		} catch (err) {
			return rejectWithValue("Search inventory materials failed.");
		}
	},
);
export const createStoreRequestAsync = createAsyncThunk(
	"inventory/createStoreRequest",
	async (data: StoreRequestData, { dispatch, rejectWithValue }) => {
		try {
			const response = await createStoreRequest(data);
			return response;
		} catch (error) {
			return rejectWithValue("Create store request fail.");
		}
	},
);
export const getAllStoreRequestsAsync = createAsyncThunk(
	"inventory/getAllStoreRequests",
	async ({
		company_id,
		projectId,
		page,
	}: {
		company_id: string;
		projectId: string;
		page: number;
	}) => {
		const response = await getAllStoreRequests(company_id, projectId, page);
		return response;
	},
);
export const searchStoreRequestsAsync = createAsyncThunk(
	"inventory/searchStoreRequests",
	async (
		{
			company_id,
			projectId,
			searchTerm,
			page,
		}: {
			company_id: string | null;
			projectId: string | undefined;
			searchTerm: string | null;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchStoreRequests(
				company_id,
				projectId,
				searchTerm,
				page,
			);
			return response;
		} catch (err) {
			return rejectWithValue("Search store requests failed.");
		}
	},
);
export const getAStoreRequestAsync = createAsyncThunk(
	"inventory/getAStoreRequest",
	async ({
		company_id,
		projectId,
		id,
	}: {
		company_id: string;
		projectId: string;
		id: string | null;
	}) => {
		const response = await getAStoreRequest(company_id, projectId, id);
		return response;
	},
);

export const returnUsageAsync = createAsyncThunk(
	"inventory/returnUsage",
	async (data: ReturnUsageData, { dispatch, rejectWithValue }) => {
		try {
			const response = await returnUsage(data);
			return response;
		} catch (err) {
			return rejectWithValue("Failed to return item.");
		}
	},
);
export const getAllUsagesAsync = createAsyncThunk(
	"inventory/getAllUsages",
	async ({
		company_id,
		projectId,
		page,
	}: {
		company_id: string;
		projectId: string;
		page: number;
	}) => {
		const response = await getAllUsages(company_id, projectId, page);
		return response;
	},
);
export const searchUsagesAsync = createAsyncThunk(
	"inventory/searchUsages",
	async (
		{
			company_id,
			projectId,
			searchTerm,
			page,
		}: {
			company_id: string | null;
			projectId: string | undefined;
			searchTerm: string | null;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchUsages(
				company_id,
				projectId,
				searchTerm,
				page,
			);
			return response;
		} catch (err) {
			return rejectWithValue("Search usages failed.");
		}
	},
);
export const getAUsageAsync = createAsyncThunk(
	"inventory/getAUsage",
	async ({
		company_id,
		projectId,
		id,
	}: {
		company_id: string;
		projectId: string;
		id: string | null;
	}) => {
		const response = await getAUsage(company_id, projectId, id);
		return response;
	},
);

export const getLowStockAsync = createAsyncThunk(
	"inventory/getLowStock",
	async (company_id: string) => {
		const response = await getLowStock(company_id);
		return response;
	},
);

const inventorySlice = createSlice({
	name: "inventory",
	initialState,
	reducers: {
		setInventoryItemId: (state, action) => {
			state.inventoryItemId = action.payload;
		},
		setInventory: (state, action) => {
			state.inventory = action.payload;
		},
		setConfirmedInventory: (state, action) => {
			state.confirmedInventory = action.payload;
		},
		setStoreRequests: (state, action) => {
			state.storeRequests = action.payload;
		},
		setStoreRequestId: (state, action) => {
			state.storeRequestId = action.payload;
		},
		setUsages: (state, action) => {
			state.usages = action.payload;
		},
		setUsageItemId: (state, action) => {
			state.usageItemId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(confirmAnInventoryAsync.fulfilled, (state, action) => {
				state.confirmInventoryResponse = action.payload;
				state.error = null;
			})
			.addCase(confirmAnInventoryAsync.rejected, (state, action) => {
				state.confirmInventoryResponse = null;
				state.error = action.error.message;
			})
			.addCase(getAllInventoryAsync.fulfilled, (state, action) => {
				state.getAllInventoryResponse = action.payload;
				state.inventory = action.payload;
				state.meta = action.payload.meta;
				state.error = null;
			})
			.addCase(getAllInventoryAsync.rejected, (state, action) => {
				state.getAllInventoryResponse = null;
				state.inventory = null;
				state.error = action.error.message;
			})
			.addCase(searchInventoryAsync.fulfilled, (state, action) => {
				state.getAllInventoryResponse = action.payload;
				state.inventory = action.payload;
				state.meta = action.payload.meta;
				state.error = null;
			})
			.addCase(searchInventoryAsync.rejected, (state, action) => {
				state.getAllInventoryResponse = null;
				state.inventory = null;
				state.error = action.error.message;
			})
			.addCase(getAnInventoryItemAsync.fulfilled, (state, action) => {
				state.getAnInventoryResponse = action.payload;
				state.inventoryItem = action.payload.data;
				state.error = null;
			})
			.addCase(getAnInventoryItemAsync.rejected, (state, action) => {
				state.getAnInventoryResponse = null;
				state.inventoryItem = null;
				state.error = action.error.message;
			})
			.addCase(getConfirmedInventoryAsync.fulfilled, (state, action) => {
				state.getConfirmedInventoryResponse = action.payload;
				state.confirmedInventory = action.payload;
				state.meta = action.payload.meta;
				state.error = null;
			})
			.addCase(searchConfirmedInventoryAsync.fulfilled, (state, action) => {
				state.getConfirmedInventoryResponse = action.payload;
				state.confirmedInventory = action.payload;
				state.meta = action.payload.meta;
				state.error = null;
			})
			.addCase(searchConfirmedInventoryAsync.rejected, (state, action) => {
				state.getConfirmedInventoryResponse = null;
				state.confirmedInventory = null;
				state.error = action.error.message;
			})
			.addCase(getConfirmedInventoryAsync.rejected, (state, action) => {
				state.getConfirmedInventoryResponse = null;
				state.confirmedInventory = null;
				state.error = action.error.message;
			})
			.addCase(createStoreRequestAsync.fulfilled, (state, action) => {
				state.createStoreRequestResponse = action.payload;
				state.error = null;
			})
			.addCase(createStoreRequestAsync.rejected, (state, action) => {
				state.createStoreRequestResponse = null;
				state.error = action.error.message;
			})
			.addCase(getAllStoreRequestsAsync.fulfilled, (state, action) => {
				state.getAllStoreRequests = action.payload;
				state.storeRequests = action.payload;
				state.meta = action.payload.meta;
				state.error = null;
			})
			.addCase(getAllStoreRequestsAsync.rejected, (state, action) => {
				state.getAllStoreRequests = null;
				state.storeRequests = null;
				state.error = action.error.message;
			})
			.addCase(searchStoreRequestsAsync.fulfilled, (state, action) => {
				state.getAllStoreRequests = action.payload;
				state.storeRequests = action.payload;
				state.meta = action.payload.meta;
				state.error = null;
			})
			.addCase(searchStoreRequestsAsync.rejected, (state, action) => {
				state.getAllStoreRequests = null;
				state.storeRequests = null;
				state.error = action.error.message;
			})
			.addCase(getAStoreRequestAsync.fulfilled, (state, action) => {
				state.getAStoreRequest = action.payload;
				state.storeRequest = action.payload.data;
				state.error = null;
			})
			.addCase(getAStoreRequestAsync.rejected, (state, action) => {
				state.getAStoreRequest = null;
				state.storeRequest = null;
				state.error = action.error.message;
			})
			.addCase(returnUsageAsync.fulfilled, (state, action) => {
				state.returnUsageResponse = action.payload;
				state.error = null;
			})
			.addCase(returnUsageAsync.rejected, (state, action) => {
				state.returnUsageResponse = null;
				state.error = action.error.message;
			})
			.addCase(getAllUsagesAsync.fulfilled, (state, action) => {
				state.getAllUsagesResponse = action.payload;
				state.usages = action.payload;
				state.meta = action.payload.meta;
				state.error = null;
			})
			.addCase(getAllUsagesAsync.rejected, (state, action) => {
				state.getAllUsagesResponse = null;
				state.usages = null;
				state.error = action.error.message;
			})
			.addCase(searchUsagesAsync.fulfilled, (state, action) => {
				state.getAllUsagesResponse = action.payload;
				state.usages = action.payload;
				state.meta = action.payload.meta;
				state.error = null;
			})
			.addCase(searchUsagesAsync.rejected, (state, action) => {
				state.getAllUsagesResponse = null;
				state.usages = null;
				state.error = action.error.message;
			})
			.addCase(getAUsageAsync.fulfilled, (state, action) => {
				state.getAUsageResponse = action.payload;
				state.usage = action.payload.data;
				state.error = null;
			})
			.addCase(getAUsageAsync.rejected, (state, action) => {
				state.getAUsageResponse = null;
				state.usage = null;
				state.error = action.error.message;
			})
			.addCase(getLowStockAsync.fulfilled, (state, action) => {
				state.getLowStockResponse = action.payload;
				state.error = null;
			})
			.addCase(getLowStockAsync.rejected, (state, action) => {
				state.getLowStockResponse = null;
				state.error = action.error.message;
			});
	},
});

export const {
	setInventoryItemId,
	setInventory,
	setConfirmedInventory,
	setStoreRequests,
	setStoreRequestId,
	setUsages,
	setUsageItemId,
} = inventorySlice.actions;

export default inventorySlice.reducer;
