/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import SettingsLayout from "../settings-layout";
import { Table, Button, Row, Col, Dropdown, Nav } from "react-bootstrap";
import CreateTeam from "./modals/create-team";
import EditTeam from "./modals/edit-team";
import Action from "../../../assets/images/action-vector.png";
import searchIcon from "../../../assets/images/search-icon.svg";
import CreateRole from "./modals/create-role";
import EditRole from "./modals/edit-role";
import {
	getAllRolesAsync,
	searchRoleAsync,
} from "../../../slices/settings/settingsSlice";
import {
	getAllUsersAsync,
	searchUserAsync,
	updateUserStatusAsync,
} from "../../../slices/user/userSlice";
import { getUserAsync } from "../../../slices/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { format } from "date-fns";
import CloseButton from "../../../components/close-button/close-button";
import ConfirmUpdateStatus from "./modals/confirm-update-status";
import Pagination from "@mui/material/Pagination";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

const Team: React.FC = () => {
	const dispatch = useAppDispatch();
	const users = useAppSelector((state) => state.user.users?.data);
	const roles = useAppSelector((state) => state.settings.roles?.data);
	const userMeta = useAppSelector((state) => state.user.users?.meta);
	const roleMeta = useAppSelector((state) => state.settings.roles?.meta);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);

	const [activeTab, setActiveTab] = useState<
		"members" | "role"
	>("members");

	const handleTabSelect = (eventKey: string | null) => {
		if (eventKey) {
			setActiveTab(eventKey as "members" | "role");
		}
	};
	const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
	const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);

	const [searchRoleQuery, setSearchRoleQuery] = useState<string>("");
	const [searchTeamQuery, setSearchTeamQuery] = useState<string>("");

	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	const handleOpenCreateModal = () => setCreateModalOpen(true);
	const handleCloseCreateModal = () => setCreateModalOpen(false);

	const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
	const handleOpenEditModal = (userId: string) => {
		setSelectedUserId(userId);
		setEditModalOpen(true);
	};
	const handleCloseEditModal = () => {
		setEditModalOpen(false);
		setSelectedUserId(null);
	};
	const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<{
		id: string;
		statusId: number;
	} | null>(null);
	const handleOpenConfirmModal = (userId: string, statusId: number) => {
		setSelectedUser({ id: userId, statusId });
		setConfirmModalOpen(true);
	};

	const handleCloseConfirmModal = () => {
		setConfirmModalOpen(false);
		setSelectedUser(null);
	};

	const [createRoleModalOpen, setCreateRoleModalOpen] =
		useState<boolean>(false);
	const handleOpenCreateRoleModal = () => setCreateRoleModalOpen(true);
	const handleCloseCreateRoleModal = () => setCreateRoleModalOpen(false);

	const [editRoleModalOpen, setEditRoleModalOpen] = useState<boolean>(false);
	const handleOpenEditRoleModal = (roleId: string) => {
		setSelectedRoleId(roleId);
		setEditRoleModalOpen(true);
	};
	const handleCloseEditRoleModal = () => {
		setEditRoleModalOpen(false);
		setSelectedRoleId(null);
	};

	const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>(
		{},
	);
	const [roleDropdownOpen, setRoleDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});

	const toggleDropdown = (index: number) => {
		setDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const toggleRoleDropdown = (index: number) => {
		setRoleDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const [refreshData, setRefreshData] = useState(false);
	const [pageNumber, setPage] = useState(1);
	const [memberPage, setMemberPage] = useState(1);
	const [rolePage, setRolePage] = useState(1);

	const [pageNumbers, setPageNumbers] = useState({
		"members": 1,
		"role": 1,
	});
	useEffect(() => {
		setPageNumbers((prevState) => ({
			...prevState,
			[activeTab]: 1,
		}));
	}, [activeTab]);

	const handleTeamChange = (
		event: React.ChangeEvent<unknown>,
		newPage: number,
	) => {
		setPageNumbers((prevState) => ({
			...prevState,
			"members": newPage,
		}));
		setMemberPage(newPage);
		if (searchTeamQuery.trim() === "") {
			dispatch(
				getAllUsersAsync({
					companyId,
					page: newPage,
				}),
			);
		} else {
			dispatch(
				searchUserAsync({
					company_id: companyId,
					searchTerm: searchTeamQuery,
					page: 1,
				}),
			);
		}
	};

	const handleRoleChange = (
		event: React.ChangeEvent<unknown>,
		newPage: number,
	) => {
		setPageNumbers((prevState) => ({
			...prevState,
			"role": newPage,
		}));
		setRolePage(newPage);
		if (searchRoleQuery.trim() === "") {
			dispatch(
				getAllRolesAsync({
					companyId,
					page: newPage,
				}),
			);
		} else {
			dispatch(
				searchRoleAsync({
					company_id: companyId,
					searchTerm: searchTeamQuery,
					page: 1,
				}),
			);
		}
	};

	useEffect(() => {
		if (activeTab === "members") {
			setMemberPage(1);
		} else if (activeTab === "role") {
			setRolePage(1);
		}
	}, [activeTab]);

	useEffect(() => {
		if (companyId) {
			const currentPage = pageNumbers[activeTab];
			if (activeTab === "members" && searchTeamQuery === "") {
				dispatch(getAllUsersAsync({ companyId, page: currentPage }));
			} else if (activeTab === "role" && searchRoleQuery === "") {
				dispatch(getAllRolesAsync({ companyId, page: currentPage }));
			}
		}
	}, [
		companyId,
		dispatch,
		pageNumbers,
		activeTab,
		searchRoleQuery,
		searchTeamQuery,
		refreshData
	]);


	const handleCreateSuccess = () => {
		setRefreshData(true);
	};

	const handleEditSuccess = () => {
		setRefreshData(true);
	};
	const handleStatusChange = async (userId: string, statusId: number) => {
		console.log(`Changing status for user ${userId} to ${statusId}`);
		const response = await dispatch(
			updateUserStatusAsync({ id: userId, companyId, statusId }),
		);
		if (response.meta.requestStatus === "fulfilled") {
			console.log("Status change successful:", response.payload);
			setRefreshData(true);
		} else {
			console.error("Status change failed:", response);
		}
	};

	const renderStatus = (statusId: number) => {
		console.log(`statusId: ${statusId}, type: ${typeof statusId}`);

		if (statusId === 1) {
			return "Active";
		} else {
			return "Inactive";
		}
	};

	const getStatusChangeAction = (statusId: number, user: { id: string }) => {
		if (statusId === 1) {
			return (
				<Dropdown.Item
					style={{ color: "red" }}
					onClick={() => handleOpenConfirmModal(user.id, 2)}
				>
					Deactivate
				</Dropdown.Item>
			);
		} else {
			return (
				<Dropdown.Item
					style={{ color: "green" }}
					onClick={() => handleOpenConfirmModal(user.id, 1)}
				>
					Activate
				</Dropdown.Item>
			);
		}
	};

	console.log("Users:", users);
	console.log("Roles:", roles);

	return (
		<div className="bg-color-8">
			<SettingsLayout>
				<div className="pt-5 p-5 ">
					<div className="w-100 mb-5 d-inline-flex justify-content-between align-items-center">
						<h4 className="text-color-2">Team</h4>
						<CloseButton />
					</div>
					<Nav
						activeKey={activeTab}
						onSelect={handleTabSelect}
						className="nav px-2 py-2"
					>
						<Nav.Item>
							<Nav.Link
								className="px-2 py-0 rounded fw-light mx-2"
								eventKey="members"
								style={{
									color: activeTab === "members" ? " #FFFFFF " : " #747487",
									textDecoration: "none",
									backgroundColor:
										activeTab === "members" ? "#0F6CBD" : "transparent",
								}}
							>
								Members
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								className=" px-2 py-0 rounded fw-light mx-2"
								eventKey="role"
								style={{
									color: activeTab === "role" ? " #FFFFFF " : " #747487",
									textDecoration: "none",
									backgroundColor:
										activeTab === "role" ? "#0F6CBD" : "transparent",
								}}
							>
								Role
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<div>
						{activeTab === "members" && (
							<div className="bg-color-9 px-5 pt-3 mt-4">
								<Row className="w-100 justify-content-end align-items-center mb-3">
									<Col md={6} sm={6} xs={12} className="mt-2">
										<Formik
											initialValues={{ searchTeamQuery: "" }}
											validationSchema={Yup.object({
												searchTeamQuery: Yup.string(),
											})}
											onSubmit={async (
												values,
												{ setSubmitting, setErrors },
											) => {
												try {
													setSubmitting(true);
													setSearchTeamQuery(values.searchTeamQuery);
													setPage(1);
													if (values.searchTeamQuery.trim() === "") {
														await dispatch(
															getAllUsersAsync({
																companyId,
																page: 1,
															}),
														);
													} else {
														await dispatch(
															searchUserAsync({
																company_id: companyId,
																searchTerm: values.searchTeamQuery,
																page: 1,
															}),
														);
													}
												} catch (error) {
													setErrors({
														searchTeamQuery:
															"An error occurred. Please try again.",
													});
												} finally {
													setSubmitting(false);
												}
											}}
										>
											{({ isSubmitting, handleSubmit, errors, touched }) => (
												<Form
													onSubmit={handleSubmit}
													className="d-flex align-items-center"
												>
													{" "}
													<div className="border border-1 rounded d-flex align-items-center">
														<Field
															type="search"
															name="searchTeamQuery"
															placeholder="Search for a member"
															className="form-control border-0"
														/>
														<button
															type="submit"
															className=""
															disabled={isSubmitting}
															style={{
																background: "none",
																border: "none",
																boxShadow: "none",
															}}
														>
															<img src={searchIcon} alt="search icon" />
														</button>
													</div>
													<ErrorMessage
														name="searchTeamQuery"
														component="div"
														className="text-danger ms-3"
													/>
												</Form>
											)}
										</Formik>
									</Col>
									<Col md={6} sm={6} xs={12} className="mt-2">
										<Button
											onClick={handleOpenCreateModal}
											className="py-3 px-5 border-0 bg-color-1 float-end"
										>
											<span className="text-center">Add member</span>
										</Button>
									</Col>
								</Row>
								<Table borderless responsive>
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">First name</th>
											<th className="text-color-1 bg-color-3">Last name</th>
											<th className="text-color-1 bg-color-3">Role</th>
											<th className="text-color-1 bg-color-3">Status</th>
											<th className="text-color-1 bg-color-3">Enrolled</th>
											<th className="text-color-1 bg-color-3">Action</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(users) && users.length > 0 ? (
											users.map((user, index) => (
												<tr key={user.id}>
													<td>{index + 1}</td>
													<td>{user.first_name}</td>
													<td>{user.last_name}</td>
													<td>{user.role_name}</td>
													<td
														style={{
															color: user.status_id === 1 ? "green" : "red",
														}}
													>
														{renderStatus(user.status_id)}
													</td>{" "}
													<td>
														{format(new Date(user.created_at), "dd/MM/yy")}
													</td>{" "}
													<td style={{ padding: "0", verticalAlign: "middle" }}>
														<Dropdown
															show={dropdownOpen[index]}
															onToggle={() => toggleDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon mb-3"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																{getStatusChangeAction(user.status_id, user)}
																<Dropdown.Item
																	onClick={() => handleOpenEditModal(user.id)}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={6} className="text-center">
													No members found.
												</td>
											</tr>
										)}
									</tbody>
								</Table>
								{userMeta && userMeta.total_pages > 1 && (
									<div className="d-flex justify-content-center mt-3 pb-5">
										<Pagination
											count={userMeta?.total_pages || 1}
											page={memberPage}
											onChange={handleTeamChange}
											shape="rounded"
											color="primary"
										/>
									</div>
								)}
							</div>
						)}
					</div>
					<div>
						{activeTab === "role" && (
							<div className="bg-color-9 px-5 pt-3 mt-4">
								<Row className="w-100 justify-content-end align-items-center mb-3">
									<Col md={6} sm={6} xs={12} className="mt-2">
										<Formik
											initialValues={{ searchRoleQuery: "" }}
											validationSchema={Yup.object({
												searchRoleQuery: Yup.string(),
											})}
											onSubmit={async (
												values,
												{ setSubmitting, setErrors },
											) => {
												try {
													setSubmitting(true);
													setSearchRoleQuery(values.searchRoleQuery);
													setPage(1);
													if (values.searchRoleQuery.trim() === "") {
														await dispatch(
															getAllRolesAsync({
																companyId,
																page: 1,
															}),
														);
													} else {
														await dispatch(
															searchRoleAsync({
																company_id: companyId,
																searchTerm: values.searchRoleQuery,
																page: 1,
															}),
														);
													}
												} catch (error) {
													setErrors({
														searchRoleQuery:
															"An error occurred. Please try again.",
													});
												} finally {
													setSubmitting(false);
												}
											}}
										>
											{({ isSubmitting, handleSubmit, errors, touched }) => (
												<Form
													onSubmit={handleSubmit}
													className="d-flex align-items-center"
												>
													{" "}
													<div className="border border-1 rounded d-flex align-items-center">
														<Field
															type="search"
															name="searchRoleQuery"
															placeholder="Search for role"
															className="form-control border-0"
														/>
														<button
															type="submit"
															className=""
															disabled={isSubmitting}
															style={{
																background: "none",
																border: "none",
																boxShadow: "none",
															}}
														>
															<img src={searchIcon} alt="search icon" />
														</button>
													</div>
													<ErrorMessage
														name="searchRoleQuery"
														component="div"
														className="text-danger ms-3"
													/>
												</Form>
											)}
										</Formik>
									</Col>
									<Col md={6} sm={6} xs={12} className="mt-2">
										<Button
											onClick={handleOpenCreateRoleModal}
											className="py-3 px-5 border-0 bg-color-1 float-end"
										>
											<span className="text-center">Add role</span>
										</Button>
									</Col>
								</Row>
								<Table borderless responsive>
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Role </th>
											<th className="text-color-1 bg-color-3">Status</th>
											<th className="text-color-1 bg-color-3">Action</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(roles) && roles.length > 0 ? (
											roles.map((role, index) => (
												<tr key={role.id}>
													<td>{index + 1}</td>
													<td>{role.role_name}</td>
													<td className="text-color-14">Active</td>
													<td style={{ padding: "0", verticalAlign: "middle" }}>
														<Dropdown
															show={roleDropdownOpen[index]}
															onToggle={() => toggleRoleDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon mb-3"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenEditRoleModal(role.id)
																	}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={6} className="text-center">
													No roles found.
												</td>
											</tr>
										)}
									</tbody>
								</Table>
								{roleMeta && roleMeta.total_pages > 1 && (
									<div className="d-flex justify-content-center mt-4">
										<Pagination
											count={roleMeta?.total_pages || 1}
											page={rolePage}
											onChange={handleRoleChange}
											shape="rounded"
											color="primary"
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
				<CreateTeam
					show={createModalOpen}
					closeModal={handleCloseCreateModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				{selectedUserId && (
					<EditTeam
						show={editModalOpen}
						closeModal={handleCloseEditModal}
						userId={selectedUserId!}
						onEditSuccess={handleEditSuccess}
					/>
				)}
				{selectedUser && (
					<ConfirmUpdateStatus
						show={confirmModalOpen}
						closeModal={handleCloseConfirmModal}
						user={selectedUser}
						onConfirm={handleStatusChange}
					/>
				)}

				<CreateRole
					show={createRoleModalOpen}
					closeModal={handleCloseCreateRoleModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				{selectedRoleId && (
					<EditRole
						show={editRoleModalOpen}
						closeModal={handleCloseEditRoleModal}
						roleId={selectedRoleId!}
						onEditSuccess={handleEditSuccess}
					/>
				)}
			</SettingsLayout>
		</div>
	);
};

export default Team;
