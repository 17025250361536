import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
	getATaskAsync,
	updateTaskAsync,
} from "../../../../slices/project-management/project-management-slice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getAllUsersAsync } from "../../../../slices/user/userSlice";
import { toast } from "react-toastify";
import { RootState } from "../../../../store/store";
import errorIcon from "../../../../assets/images/error-icon.svg";

interface UpdateTaskValues {
	task_name: string;
	assigned_to: number;
	start_date: string;
	end_date: string;
	task_status: string;
	task_color: string;
}

interface UpdateTaskProps {
	closeModal: () => void;
	show: boolean;
	project_id: string | undefined | null;
	id: string;
	onEditSuccess: () => void;
}

const UpdateTask: React.FC<UpdateTaskProps> = ({
	closeModal,
	show,
	project_id,
	id,
	onEditSuccess,
}) => {
	const dispatch = useAppDispatch();
	const [pageNumber, setPage] = useState(1);
	const company_id = localStorage.getItem("userCompanyID");
	const task = useAppSelector((state) => state.projectManagement.taskData);

	useEffect(() => {
		if (project_id && company_id && id !== "") {
			dispatch(getATaskAsync({ id, company_id, projectId: project_id }));
		}
	}, [company_id, dispatch, id, project_id]);

	const users = useAppSelector((state) => state.user.users?.data);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	useEffect(() => {
		if (companyId) {
			dispatch(getAllUsersAsync({ companyId, page: pageNumber }));
		}
	}, [companyId, pageNumber, dispatch]);

	return (
		<Container fluid className="">
			<Modal
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						enableReinitialize
						initialValues={{
							task_name: task?.task_name || "",
							assigned_to: task?.assigned_to || 0,
							start_date: task?.start_date || "",
							end_date: task?.end_date || "",
							task_status: task?.task_status || "",
							task_color: task?.task_color || "",
						}}
						validationSchema={Yup.object().shape({
							task_name: Yup.string().required("Required"),
							assigned_to: Yup.number()
								.required("Required")
								.min(1, "Choose a user"),
							start_date: Yup.string().required("Required"),
							end_date: Yup.date()
								.required("Required")
								.test(
									"is-after-start",
									"End date must be after start date",
									function (value) {
										const { start_date } = this.parent;
										return start_date && value
											? new Date(value) > new Date(start_date)
											: true;
									},
								),
							task_status: Yup.string().required("Required"),
							task_color: Yup.string().required("Required"),
						})}
						onSubmit={(values: UpdateTaskValues, { setSubmitting }) => {
							dispatch(
								updateTaskAsync({
									company_id: company_id,
									projectId: project_id,
									id: id,
									task_name: values.task_name,
									assigned_to: values.assigned_to,
									start_date: values.start_date,
									end_date: values.end_date,
									task_status: values.task_status,
									task_color: values.task_color,
								}),
							)
								.unwrap()
								.then((response) => {
									toast.success(response.message);
									onEditSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message)
										.flat()
										.join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
						validateOnChange
						validateOnBlur
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
							dirty,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-2">
									<h4>Edit Task</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="my-2">
												Task name <span className="text-danger">*</span>
											</label>
											<Field
												type="text"
												name="task_name"
												className={`fluent-input  ${
													touched.task_name && errors.task_name
														? "is-invalid"
														: ""
												}`}
											></Field>
											{touched.task_name && errors.task_name ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.task_name}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="my-2">
												Assign task to <span className="text-danger">*</span>
											</label>
											<FormControl fullWidth>
												<InputLabel>Member</InputLabel>
												<Select
													label="Member"
													name="assigned_to"
													value={values.assigned_to}
													className={`fluent-input  ${
														touched.assigned_to && errors.assigned_to
															? "is-invalid"
															: ""
													}`}
													onChange={handleChange}
												>
													{users !== undefined && users !== null ? (
														users.map((user) => (
															<MenuItem key={user.id} value={user.id}>
																{user.first_name} {user.last_name}
															</MenuItem>
														))
													) : (
														<MenuItem value="">John Doe</MenuItem>
													)}
												</Select>
											</FormControl>
											{touched.assigned_to && errors.assigned_to ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.assigned_to}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="my-2">
												Planned start date{" "}
												<span className="text-danger">*</span>
											</label>
											<Field
												type="date"
												name="start_date"
												className={`fluent-input  ${
													touched.start_date && errors.start_date
														? "is-invalid"
														: ""
												}`}
											></Field>
											{touched.start_date && errors.start_date ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.start_date}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="my-2">
												Planned end date <span className="text-danger">*</span>
											</label>
											<Field
												type="date"
												name="end_date"
												className={`fluent-input  ${
													touched.end_date && errors.end_date
														? "is-invalid"
														: ""
												}`}
											></Field>
											{touched.end_date && errors.end_date ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.end_date}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="my-2">
												Task status <span className="text-danger">*</span>
											</label>
											<Field
												as="select"
												name="task_status"
												value={values.task_status}
												className={`fluent-input  ${
													touched.task_status && errors.task_status
														? "is-invalid"
														: ""
												}`}
											>
												<option value="To do">To do</option>
												<option value="In progress">In progress</option>
												<option value="Done">Done</option>
											</Field>
											{touched.task_status && errors.task_status ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{errors.task_status}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="my-2">
												Task Color <span className="text-danger">*</span>
											</label>
											<Field
												type="color"
												name="task_color"
												value={values.task_color}
												className={`fluent-input  ${
													touched.task_color && errors.task_color
														? "is-invalid"
														: ""
												}`}
											/>
										</Col>
										{touched.task_color && errors.task_color ? (
											<p className="error-message mb-0 d-inline-flex align-items-center">
												<img src={errorIcon} alt="error-icon" />
												{errors.task_color}
											</p>
										) : (
											<p className="error-message-placeholder mb-0">
												no errors
											</p>
										)}
									</Row>

									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														className="border-0 create-btn bg-color-1"
														onClick={() => handleSubmit()}
														disabled={!dirty || isSubmitting}
													>
														{`${
															isSubmitting ? "Editing task..." : "Edit task"
														} `}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default UpdateTask;
