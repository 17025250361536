import React from "react";
import { Container, Modal, Button } from "react-bootstrap";

interface ViewImageProps {
	show: boolean;
	closeModal: () => void;
	selectedImage: string | null;
	issueName?: string;
}
const ViewImage: React.FC<ViewImageProps> = ({ show, closeModal, selectedImage, issueName }) => {
	return (
		<Container>
			<Modal show={show} onHide={closeModal} centered>
				<Modal.Header closeButton className="text-start mt-2">
					<h4>{issueName ? `${issueName} Image` : "Issue Image"}</h4>
				</Modal.Header>
				<Modal.Body>
					{selectedImage ? (
						<img src={selectedImage} alt={issueName} style={{ width: "100%", height: "auto" }} />
					) : (
						<p>No Image Available</p>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={closeModal} className="border-0 create-btn bg-color-1">
						<span className="text-center">Close</span>
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
};

export default ViewImage;
