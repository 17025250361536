import React, { useEffect } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { createIssueAsync } from "../../../../slices/project-management/project-management-slice";
import { getAllDropdownTasksAsync } from "../../../../slices/project-management/project-management-slice";
import { getAllDropdownUsersAsync } from "../../../../slices/user/userSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface CreateIssueValues {
	issue_name: string;
	associated_task: number;
	assigned_to: number;
	priority: string;
	status: string;
	image: string;
}

interface CreateIssueProps {
	closeModal: () => void;
	show: boolean;
	project_id: string | undefined | null;
	onCreateSuccess: () => void;
}

const CreateIssue: React.FC<CreateIssueProps> = ({
	closeModal,
	show,
	project_id,
	onCreateSuccess,
}) => {
	const dispatch = useAppDispatch();
	const companyId = localStorage.getItem("userCompanyID");
	const tasks = useAppSelector((state) => state.projectManagement.tasks?.data);
	const users = useAppSelector((state) => state.user.users?.data);
	const { projectId } = useParams<{ projectId: string }>();

	useEffect(() => {
		if (companyId && projectId) {
			dispatch(getAllDropdownTasksAsync({
				companyId,
				projectId
			}));
		}
	}, [companyId, projectId, dispatch]);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllDropdownUsersAsync({ companyId }));
		}
	}, [companyId, dispatch]);
	return (
		<Container fluid className="">
			<Modal
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							issue_name: "",
							associated_task: 0,
							assigned_to: 0,
							priority: "",
							status: "",
							image: "",
						}}
						validationSchema={Yup.object().shape({
							issue_name: Yup.string().required("Required"),
							associated_task: Yup.number().required("Required"),
							assigned_to: Yup.string().required("Required"),
							priority: Yup.string().required("Required"),
							status: Yup.string().required("Required"),
							image: Yup.mixed().required("Required"),
						})}
						onSubmit={(values: CreateIssueValues, { setSubmitting }) => {
							if (companyId) {
								const createIssuesData = {
									issue_name: values.issue_name,
									associated_task: values.associated_task,
									assigned_to: values.assigned_to,
									priority: values.priority,
									status: values.status,
									company_id: companyId,
									projectId: project_id || "",
									image: values.image,
								};
								dispatch(createIssueAsync(createIssuesData))
									.unwrap()
									.then((response) => {
										
										onCreateSuccess();
										closeModal();
									})
									.catch((error) => {
										const errorMessage = Object.values(error.message).flat().join(" ");
										toast.error(errorMessage);
									})
									.finally(() => {
										setSubmitting(false);
									});
							} else {
								console.error("project_id or company_id is undefined");
							}
						}}

						validateOnChange
						validateOnBlur
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
							setFieldValue,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-2">
									<h4>Create issue</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Issue name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="issue_name"
												className={`fluent-input  ${touched.issue_name && errors.issue_name
													? "is-invalid"
													: ""
													}`}
												required
											></Field>
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Associate task <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Task</InputLabel>
												<Select
													label="Member"
													name="associated_task"
													className={`fluent-input  ${touched.associated_task && errors.associated_task
														? "is-invalid"
														: ""
														}`}
													onChange={handleChange}
													required
												>
													{tasks !== undefined && tasks !== null ? (
														tasks?.map((task) => (
															<MenuItem key={task.id} value={task.id}>
																{task.task_name}
															</MenuItem>
														))
													) : (
														<MenuItem value="">John Doe</MenuItem>
													)}
												</Select>
											</FormControl>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="my-2">
												Assign task to <span className="text-danger">*</span>
											</label>
											<FormControl fullWidth>
												<InputLabel>Member</InputLabel>
												<Select
													label="Member"
													name="assigned_to"
													className={`fluent-input  ${touched.assigned_to && errors.assigned_to
														? "is-invalid"
														: ""
														}`}
													onChange={handleChange}
													required
												>
													{users !== undefined && users !== null ? (
														users?.map((user) => (
															<MenuItem key={user.id} value={user.id}>
																{user.first_name} {user.last_name}
															</MenuItem>
														))
													) : (
														<MenuItem value="">John Doe</MenuItem>
													)}
												</Select>
											</FormControl>
											{touched.assigned_to && errors.assigned_to ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													{errors.assigned_to}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Priority <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Priority</InputLabel>
												<Select
													label="Member"
													name="priority"
													className={`fluent-input  ${touched.priority && errors.priority
														? "is-invalid"
														: ""
														}`}
													onChange={handleChange}
													required
												>
													<MenuItem value="High">High</MenuItem>
													<MenuItem value="Medium">Medium</MenuItem>
													<MenuItem value="Low">Low</MenuItem>
												</Select>
											</FormControl>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Status <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Status</InputLabel>
												<Select
													label="status"
													name="status"
													className={`fluent-input  ${touched.status && errors.status
														? "is-invalid"
														: ""
														}`}
													onChange={handleChange}
													required
												>
													<MenuItem value="To do">To do</MenuItem>
													<MenuItem value="In progress">In progress</MenuItem>
													<MenuItem value="Done">Done</MenuItem>
												</Select>
											</FormControl>
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Image <span className="text-danger">*</span>
												</p>
											</label>
											<input
												type="file"
												name="image"
												className={`fluent-input ${touched.image && errors.image ? "is-invalid" : ""}`}
												accept="image/*"
												onChange={(event) => {
													if (event.currentTarget.files && event.currentTarget.files[0]) {
														setFieldValue("image", event.currentTarget.files[0]); // Save the file
													}
												}}
												required
											/>
											{touched.image && errors.image && <p className="text-danger">{errors.image}</p>}
										</Col>
									</Row>

									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className="border-0 create-btn bg-color-1"
														disabled={isSubmitting}
													>
														<span className="text-center">
															{isSubmitting ? `Creating...` : `Create`}
														</span>{" "}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateIssue;
